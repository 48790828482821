import React, { useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import { auth } from '../services/firebaseConfig';
import { useLocation, useNavigate } from 'react-router-dom';
import './overrides/FirebaseUIOverrides.css';

const PhoneVerification = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { data: event } = location.state || {};

    console.log('location:', location);
    console.log('event:', event);

    useEffect(() => {
        auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
            .then(() => {
                let ui = firebaseui.auth.AuthUI.getInstance();
                if (!ui) {
                    ui = new firebaseui.auth.AuthUI(auth);
                }

                const uiConfig = {
                    signInOptions: [
                        {
                            provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
                            recaptchaParameters: {
                                type: 'image',
                                size: 'normal',
                                badge: 'bottomleft',
                            },
                            defaultCountry: 'IL',
                            whitelistedCountries: ['+972'],
                        }
                    ],
                    callbacks: {
                        signInSuccessWithAuthResult: (authResult: { additionalUserInfo: { isNewUser: any; }; }) => {
                            console.log('User successfully signed in:', authResult);
                            const isNewUser = authResult.additionalUserInfo?.isNewUser;
                            if (isNewUser) {
                                console.log('This is a new user, setting up profile.');
                                navigate('/onboarding', { state: { data: event } });
                            } else if (!isNewUser && event && event.id) {
                                console.log('This is an existing user with event.');
                                if (location.state.isPayment) {
                                    navigate('/payment/' + event.id, { state: { data: { event_id: event.id, name: event.name, date: event.date } } });
                                }
                                else {
                                    navigate('/events/' + event.id);
                                }
                            }
                            else {
                                console.log('This is an existing user.');
                                navigate('/events');
                            }
                            return false;
                        },
                        signInFailure: (error: any) => {
                            console.error('Sign in failed:', error);
                        },
                        uiShown: () => {
                            // UI is shown for the initial step (phone number).
                            // The verification code input will appear later.
                            observeForCodeInput();
                        }
                    },
                    credentialHelper: firebaseui.auth.CredentialHelper.NONE,
                };

                ui.start('#firebaseui-auth-container', uiConfig);

                // Cleanup on unmount
                return () => {
                    if (ui) {
                        ui.reset();
                    }
                };
            })
            .catch((error) => {
                console.error('Error setting persistence:', error);
            });
    }, [navigate, event]);

    const observeForCodeInput = () => {
        const container = document.getElementById('firebaseui-auth-container');
        if (!container) return;

        const observer = new MutationObserver(() => {
            const codeInput = container.querySelector('.firebaseui-id-phone-confirmation-code');
            if (codeInput) {
                // Found the verification code input
                codeInput.setAttribute('autocomplete', 'one-time-code');
                codeInput.setAttribute('inputmode', 'numeric');
                codeInput.setAttribute('pattern', '[0-9]*');

                // Stop observing once we’ve applied the attributes
                observer.disconnect();

                // Attempt Web OTP autofill now that the correct input is present
                attemptWebOTPAutoFill();
            }
        });

        // Observe for DOM changes to find when the code input is rendered
        observer.observe(container, { childList: true, subtree: true });
    };

    const attemptWebOTPAutoFill = async () => {
        if ('OTPCredential' in window) {
            try {
                const ac = new AbortController();
                const content = await (navigator.credentials as any).get({
                    otp: { transport: ['sms'] },
                    signal: ac.signal
                });
                if (content && content.code) {
                    const codeInput: any = document.querySelector('#firebaseui-auth-container .firebaseui-id-phone-confirmation-code');
                    if (codeInput) {
                        codeInput.value = content.code;
                        // Trigger input event so FirebaseUI notices the change
                        const event = new Event('input', { bubbles: true });
                        codeInput.dispatchEvent(event);
                    }
                }
            } catch (err) {
                console.error('Web OTP API Error:', err);
            }
        } else {
            console.log('Web OTP API not supported in this browser.');
        }
    };

    return (
        <div>
            <h2>Enter the code we sent you</h2>
            <div id="firebaseui-auth-container"></div>
        </div>
    );
};

export default PhoneVerification;