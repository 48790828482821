import React, { useState } from 'react';
import {
    Box,
    Chip,
    Typography,
    Button,
    TextField,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import ProfileHeader from './ProfileHeader';
import { updateUserDocument, deleteUserAndData } from '../../shared/services/userService';
import { getAuth, signOut, deleteUser } from 'firebase/auth';

interface User {
    user: any;
    firstName: string;
    purpose: string[];
    socialMediaUrl: string;
    occupation: string;
    birthday: any;
    bio: string;
    badges: string[];
    image: string;
    gender: string;
    email: string;
    uid : string;
}

interface ProfilePageProps {
    user: User;
}

const ProfilePage: React.FC<ProfilePageProps> = ({ user }) => {
    const [editMode, setEditMode] = useState(false);
    const [updatedUser, setUpdatedUser] = useState({
        ...user,
        purpose: user.purpose || [], // Default to empty array if undefined
        badges: user.badges || [], // Default to empty array if undefined
    });
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const auth = getAuth();

    const toggleEditMode = () => {
        setEditMode(!editMode);
        if (!editMode) {
            setUpdatedUser({ ...user }); // Reset changes if exiting edit mode
        }
    };

    const handleChange = (field: string, value: string) => {
        setUpdatedUser((prev) => ({ ...prev, [field]: value }));
    };

    const handleSave = () => {
        console.log('Saved User Data:', updatedUser);
        setEditMode(false);
        const { user: userProp, ...userWithoutUserProp } = updatedUser;
        updateUserDocument(user.uid, userWithoutUserProp);
    };

    const handleOpenDialog = () => setIsDialogOpen(true);
    const handleCloseDialog = () => setIsDialogOpen(false);

    const handleDeleteUser = async () => {
        try {
            const currentUser = auth.currentUser;

            if (!currentUser) {
                console.error('No authenticated user found.');
                return;
            }

            // Refresh the user's token to ensure the session is valid
            console.log('Refreshing token to ensure session validity...');
            await currentUser.getIdToken(true);

            // Delete user document from Firestore
            console.log('Deleting user document from Firestore...');
            await deleteUserAndData(currentUser.uid); // Ensure this deletes user-related Firestore data
            console.log('User document deleted successfully.');

            // Delete the user from Firebase Authentication
            console.log('Deleting user from Firebase Authentication...');
            await deleteUser(currentUser);
            console.log('User deleted from Firebase Authentication successfully.');

            // Inform the user and redirect or update the UI
            handleCloseDialog();
            console.log('User deletion complete. Update UI or navigate as needed.');
        } catch (error) {
            console.error('Error during user deletion:', error);
        }
    };

    return (
        <Box
            sx={{
                padding: '16px',
                backgroundColor: '#f9f9f9',
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative',
            }}
        >
            <IconButton
                onClick={toggleEditMode}
                sx={{
                    position: 'absolute',
                    margin: '16px',
                    top: '16px',
                    left: '16px',
                    backgroundColor: '#fff',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                    '&:hover': {
                        backgroundColor: '#f0f0f0',
                    },
                    zIndex: 10,
                }}
            >
                {editMode ? <CloseIcon /> : <EditIcon />}
            </IconButton>

            <Box sx={{ marginBottom: '24px', width: '100%', maxWidth: '600px' }}>
                <ProfileHeader
                    user={{
                        firstName: updatedUser.firstName,
                        image: updatedUser.image,
                        birthday: updatedUser.birthday,
                        lastName: '',
                        role: '',
                        location: '',
                    }}
                />
            </Box>

            {/* About Me Section */}
            <Box
                sx={{
                    padding: '16px',
                    backgroundColor: '#ffffff',
                    borderRadius: '8px',
                    marginBottom: '16px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    width: '100%',
                    maxWidth: '600px',
                }}
            >
                <Typography variant="h6" fontWeight="bold" marginBottom="8px">
                    About Me
                </Typography>
                {editMode ? (
                    <TextField
                        fullWidth
                        multiline
                        rows={3}
                        variant="outlined"
                        value={updatedUser.bio}
                        onChange={(e) => handleChange('bio', e.target.value)}
                    />
                ) : (
                    <Typography>{updatedUser.bio || 'No bio provided'}</Typography>
                )}
            </Box>

            {/* Goals Section */}
            <Box
                sx={{
                    padding: '16px',
                    backgroundColor: '#ffffff',
                    borderRadius: '8px',
                    marginBottom: '16px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    width: '100%',
                    maxWidth: '600px',
                }}
            >
                <Typography variant="h6" fontWeight="bold" marginBottom="8px">
                    Goals
                </Typography>
                <Box display="flex" flexWrap="wrap" gap="8px">
                    {updatedUser.purpose?.map((goal, index) => (
                        <Chip key={index} label={goal} />
                    ))}
                </Box>
            </Box>

            {/* Values I Cherish Section */}
            <Box
                sx={{
                    padding: '16px',
                    backgroundColor: '#ffffff',
                    borderRadius: '8px',
                    marginBottom: '16px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    width: '100%',
                    maxWidth: '600px',
                }}
            >
                <Typography variant="h6" fontWeight="bold" marginBottom="8px">
                    Values I Cherish
                </Typography>
                <Box display="flex" flexWrap="wrap" gap="8px">
                    {updatedUser.badges?.map((badge, index) => (
                        <Chip key={index} label={badge} />
                    ))}
                </Box>
            </Box>

            {/* Save/Cancel Buttons */}
            {editMode && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: '16px',
                        width: '100%',
                        maxWidth: '600px',
                    }}
                >
                    <Button variant="contained" color="primary" onClick={handleSave}>
                        <SaveIcon sx={{ marginRight: '8px' }} /> Save
                    </Button>
                    <Button variant="outlined" color="secondary" onClick={toggleEditMode}>
                        Cancel
                    </Button>
                </Box>
            )}

            <Box
                sx={{
                    position: 'absolute',
                    bottom: '16px',
                    right: '16px',
                }}
            >
            </Box>

            <Button
                        variant="text"
                        color="error"
                        onClick={handleOpenDialog}
                        sx={{
                            fontWeight: 'bold',
                            textTransform: 'none',
                            padding: '8px',
                            alignSelf: 'flex-end', // Align the button to the left
                        }}
                    >
                        Delete My Account
                    </Button>

            <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <Typography>
                        Are you sure you want to delete your account? This action cannot be undone.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteUser} color="error">
                        Yes, Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default ProfilePage;
