// src/eventsService.js
import { db } from './firebaseConfig';
import {
    collection,
    addDoc,
    getDocs,
    doc,
    updateDoc,
    deleteDoc,
    getDoc,
    query,
    where,
    orderBy,
} from 'firebase/firestore';
import { getUserEvents } from './userService';


import axios from 'axios';

// Reference to the 'events' collection
const eventsCollection = collection(db, 'Events');
// Create a new event
export const createEvent = async (eventData: any) => {
    try {
        const docRef = await addDoc(eventsCollection, eventData);
        return docRef.id;
    } catch (error) {
        console.error('Error adding event: ', error);
        throw error;
    }
};

export const getEventById = async (id: any) => {
    try {
        // Create a query to find the document by its ID
        const eventDocRef = doc(db, 'Events', id);
        const eventDocSnap = await getDoc(eventDocRef);
        if (eventDocSnap.exists()) {
            return { id: eventDocSnap.id, ...eventDocSnap.data() };
        } else {
            return null;
        }

    } catch (error) {
        console.error('Error fetching event by ID:', error);
        throw error; // You can also handle this error differently if needed
    }
};

// Approve user for event
export const approveUserForEvent = async (eventId: string, userId: string, status: string) => {
    try {
        const q = query(eventsCollection, where('event_id', '==', eventId));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
            const eventDoc = querySnapshot.docs[0];
            const eventDocRef = doc(db, 'Events', eventDoc.id);
            const eventDocSnap = await getDoc(eventDocRef);

            if (eventDocSnap.exists()) {
                const guests = eventDocSnap.data()?.guests || [];
                const updatedGuests = guests.filter((guest: any) => guest.user_id !== userId);
                const userToApprove = guests.find((guest: any) => guest.user_id === userId);
                if (userToApprove) {
                    userToApprove.status = status;
                    updatedGuests.push(userToApprove);
                }
                await updateDoc(eventDocRef, {
                    guests: updatedGuests
                });
                return true;
            }
            // Rest of the code...
        } else {
            console.error('No event found with the specified event_id');
        }
        console.log('User approved for event successfully');
    } catch (error) {
        console.error('Error approving user for event:', error);
    }
}

// Add user to event
export const addUserToEvent = async (eventId: string, user: any) => {
    try {
        // Reference to the event document by its ID
        const eventDocRef = doc(db, 'Events', eventId);
        const eventDocSnap = await getDoc(eventDocRef);

        console.log('addUserToEvent ad user');
        console.log(user);

        if (eventDocSnap.exists()) {
            const eventDoc = eventDocSnap.data();
            const users = eventDoc?.guests || [];

            // Check if the user is already registered for the event
            const isUserRegistered = users.some((guest: any) => guest.user_id === user.uid);

            if (!isUserRegistered) {
                const userItem = {
                    user_id: user.user?.uid || user.uid,
                    status: 'pending',
                    username: user.user?.firstName || user.firstName,
                    image: user.image || ''
                };
                users.push(userItem);

                // Ensure no undefined values in the userItem
                const sanitizedUsers = users.map((guest: any) => ({
                    ...guest,
                    image: guest.image || ''
                }));

                // Update the document with the new guest list
                await updateDoc(eventDocRef, {
                    guests: sanitizedUsers
                });

                const payload = { to: +972547678267, message: 'someone register222222' };
                console.log('Payload being sent to sendwhatsapmsg:', payload);

                try {
                    //const sendWhatsappMsg = await axios.post(`https://salonim-29dcf-default-rtdb.europe-west1.firebasedatabase.app/joni/send.json`, payload);
                  //  console.log('Message sent successfully with ID:', sendWhatsappMsg);
                } catch (error) {
                    console.error('Failed to send message:', error);
                }

                const firstName =  user?.firstName|| "";
                
                const phoneNumber =  user?.phoneNumber|| "";

                console.log('firstName');
                
                console.log(firstName);
                
                console.log('phoneNumber');
                
                console.log(phoneNumber);

                const message22 =`היי ${firstName}, הבקשה שלך לאירוע התקבלה. אם תאושר, תקבל הודעה כאן.`;

                const payload2 = { to: phoneNumber, message: message22 };
                console.log('Payload being sent to sendwhatsapmsg:', payload2);
    
                try {
                    const sendWhatsappMsg = await axios.post(`https://salonim-29dcf-default-rtdb.europe-west1.firebasedatabase.app/joni/send.json`, payload2);
                    console.log('Message sent successfully with ID 2 :', sendWhatsappMsg);
                } catch (error) {
                    console.error('Failed to send message:', error);
                }



                console.log('User added to event successfully');
            } else {
                console.log('User is already registered for this event');
            }
        } else {
            console.error('No event found with the specified event_id');
        }

    } catch (error) {
        console.error('Error adding user to event:', error);
        throw error;
    }
};

// Remove user from event
export const removeUserFromEvent = async (eventId: string, userId: string) => {
    try {
        // Reference to the event document by its ID
        const eventDocRef = doc(db, 'Events', eventId);
        const eventDocSnap = await getDoc(eventDocRef);

        if (eventDocSnap.exists()) {
            const eventDoc = eventDocSnap.data();
            const users = eventDoc?.guests || [];

            // Filter out the user to be removed
            const updatedUsers = users.filter((guest: any) => guest.user_id !== userId);

            // Update the document with the new guest list
            await updateDoc(eventDocRef, {
                guests: updatedUsers
            });

            console.log('User removed from event successfully');
        } else {
            console.error('No event found with the specified event_id');
        }

    } catch (error) {
        console.error('Error removing user from event:', error);
        throw error;
    }
};

// Read events (fetch all events)
export const getEvents = async (id: any, type: string = '') => {
    try {
        const querySnapshot = await getDocs(eventsCollection);
        const events: {
            [x: string]: any; id: string;
        }[] = [];
        await Promise.all(querySnapshot.docs.map(async (doc) => {
            if (type === 'my') {
                if (id !== '') {
                    const userEvents = await getUserEvents(id); // Await the getUserEvents function call
                    const eventDocData = await doc.data();
                    if (userEvents.map((event: any) => event.event_id).includes(eventDocData.event_id)) {
                        events.push({ id: doc.id, ...eventDocData });
                    }
                }
            } else {
                events.push({ id: doc.id, ...doc.data() });
            }
        }));
        return events;
    } catch (error) {
        console.error('Error fetching events: ', error);
        throw error;
    }
};

// Get events by user ID
export const getEventsByUserId = async (userId: string) => {
    try {
        const q = query(eventsCollection, where('uid', '==', userId));
        const querySnapshot = await getDocs(q);
        const events: { id: string; }[] = [];
        querySnapshot.forEach((doc) => {
            events.push({ id: doc.id, ...doc.data() });
        });
        return events;
    } catch (error) {
        console.error('Error fetching events by user ID:', error);
        throw error;
    }
};

// Read events with conditions (e.g., upcoming or past)
export const getEventsByCondition = async (condition: string) => {
    try {
        let q;
        if (condition === 'upcoming') {
            q = query(
                eventsCollection,
                where('date', '>=', new Date()),
                orderBy('date', 'asc')
            );
        } else if (condition === 'past') {
            q = query(
                eventsCollection,
                where('date', '<', new Date()),
                orderBy('date', 'desc')
            );
        } else {
            q = eventsCollection;
        }

        const querySnapshot = await getDocs(q);
        const events: { id: string; }[] = [];
        querySnapshot.forEach((doc) => {
            events.push({ id: doc.id, ...doc.data() });
        });
        return events;
    } catch (error) {
        console.error('Error fetching events: ', error);
        throw error;
    }
};

// Update an event
export const updateEvent = async (eventId: string, updatedData: any) => {
    try {
        const eventDoc = doc(db, 'events', eventId);
        await updateDoc(eventDoc, updatedData);
    } catch (error) {
        console.error('Error updating event: ', error);
        throw error;
    }
};

// Delete an event
export const deleteEvent = async (eventId: string) => {
    try {
        const eventDoc = doc(db, 'events', eventId);
        await deleteDoc(eventDoc);
    } catch (error) {
        console.error('Error deleting event: ', error);
        throw error;
    }
};
