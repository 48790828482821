import React, { useState } from 'react';
import { Box, Typography, IconButton, Button } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface GenderScreenProps {
  onNext: () => void;        // Function to handle the next button click
  onBack: () => void;        // Function to handle the back button click
  setGender: (gender: string) => void; // Function to set the selected gender
  gender: string;            // Current selected gender
  name: string;              // User's name
  skipButton?: React.ReactNode; // Optional skip button (could be a <Button /> or any React node)
}

const GenderScreen: React.FC<GenderScreenProps> = ({
  onNext,
  onBack,
  setGender,
  gender,
  name,
  skipButton,
}) => {
  const [selectedGender, setSelectedGender] = useState(gender);

  // Function to handle gender selection
  const handleGenderSelect = (value: string) => {
    setSelectedGender(value); // Update local state
    setGender(value);         // Update parent state
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '20px',
        width: '100%',
        maxWidth: '393px',
        margin: '0 auto',
        backgroundColor: '#FFFFFF',
        height: '100vh',
      }}
    >
      {/* Progress Indicator */}
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
        <Box
          sx={{
            width: '56px',
            height: '4px',
            backgroundColor: '#000000',
            borderRadius: '9999px',
          }}
        />
      </Box>

      {/* Title and Subtitle */}
      <Box sx={{ padding: '0 0 20px' }}>
        <Typography
          variant="h4"
          sx={{ fontWeight: 700, fontSize: '32px', lineHeight: '38px', marginBottom: '12px' }}
        >
          {name} is a great name {/* Display user's name */}
        </Typography>
        <Typography sx={{ fontWeight: 500, fontSize: '14px', lineHeight: '24px', color: '#313131' }}>
          We're glad you're here. Please select the gender.
        </Typography>
      </Box>

      {/* Gender Options */}
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '16px', marginTop: '40px' }}>
        <Box
          onClick={() => handleGenderSelect('Woman')}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: '20px 0px 20px 10px',
            width: '100%',
            backgroundColor: selectedGender === 'Woman' ? '#007AFF' : '#F2F2F2',
            borderRadius: '12px',
            cursor: 'pointer',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <Typography
              sx={{
                marginBottom: '0px',
                fontWeight: 500,
                fontSize: '16px',
                color: selectedGender === 'Woman' ? '#ffffff' : '#000000',
              }}
            >
              Woman
            </Typography>
            {selectedGender === 'Woman' && (
              <ArrowForwardIcon
                sx={{
                  marginRight: '10px',
                  color: '#ffffff',
                }}
              />
            )}
          </Box>
        </Box>

        <Box
          onClick={() => handleGenderSelect('Man')}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '20px 0px 20px 10px',
            width: '100%',
            backgroundColor: selectedGender === 'Man' ? '#007AFF' : '#F2F2F2',
            borderRadius: '12px',
            cursor: 'pointer',
          }}
        >
          <Typography
            sx={{
              marginBottom: '0px',
              fontWeight: 500,
              fontSize: '16px',
              color: selectedGender === 'Man' ? '#ffffff' : '#000000',
            }}
          >
            Man
          </Typography>
          {selectedGender === 'Man' && (
            <ArrowForwardIcon
              sx={{
                marginRight: '10px',
                color: '#ffffff',
              }}
            />
          )}
        </Box>
      </Box>

      {/* Navigation Buttons */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '24px' }}>
        {/* If a skip button is provided, render it on the left */}
        {skipButton && (
          <Box>{skipButton}</Box>
        )}

        {/* Conditionally render the "Next" button only if a gender is selected */}
        {selectedGender && (
          <IconButton
            onClick={onNext}
            sx={{ backgroundColor: '#007AFF', color: '#fff' }}
          >
            <ArrowForwardIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default GenderScreen;