import React, { ReactNode, useState } from 'react';
import { Box, Typography, TextField, IconButton } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface IntroScreenProps {
    onNext: () => void;
    setFirstName: (firstName: string) => void;
    setBirthday: (birthday: { day: string; month: string; year: string }) => void;
    firstName: string;
    birthday: { day: string; month: string; year: string };
    skipButton?: ReactNode; // Skip button callback
}

const IntroScreen: React.FC<IntroScreenProps> = ({
    onNext,
    setFirstName,
    setBirthday,
    firstName,
    birthday,
    skipButton
}) => {
    const [errors, setErrors] = useState<{ firstName?: string }>({});

    const validate = () => {
        const newErrors: { firstName?: string } = {};

        // Only validate "firstName"
        if (!firstName.trim()) {
            newErrors.firstName = 'First name is required.';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = () => {
        if (validate()) {
            onNext();
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                padding: '16px',
                maxWidth: '393px',
                margin: '0 auto',
            }}
        >
            <Typography variant="h4" sx={{ fontWeight: 'bold', fontSize: '38px', marginBottom: '40px' }}>
                Oh hey! Let's start with an intro.
            </Typography>

            {/* Full Name Field */}
            <Typography sx={{ marginBottom: '8px' }}>Your full name</Typography>
            <TextField
                placeholder="Full Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                error={!!errors.firstName}
                helperText={errors.firstName}
                fullWidth
                sx={{ marginBottom: '36px', backgroundColor: '#f5f5f5', borderRadius: '8px' }}
            />

            {/* Birthday Fields (NOT required) */}
            <Typography sx={{ marginBottom: '8px' }}>Your birthday (optional)</Typography>
            <Box sx={{ display: 'flex', gap: '10px', marginBottom: '16px', width: '100%' }}>
                <TextField
                    placeholder="Day"
                    value={birthday.day}
                    onChange={(e) => setBirthday({ ...birthday, day: e.target.value })}
                    sx={{ flex: 1 }}
                />
                <TextField
                    placeholder="Month"
                    value={birthday.month}
                    onChange={(e) => setBirthday({ ...birthday, month: e.target.value })}
                    sx={{ flex: 1 }}
                />
                <TextField
                    placeholder="Year"
                    value={birthday.year}
                    onChange={(e) => setBirthday({ ...birthday, year: e.target.value })}
                    sx={{ flex: 1 }}
                />
            </Box>

            <Typography sx={{ fontSize: '12px', color: '#888' }}>
                It’s never too early to count down
            </Typography>

            {/* Next Button */}
            <IconButton
                onClick={handleSubmit}
                sx={{ alignSelf: 'flex-end', marginTop: '24px', backgroundColor: '#007AFF', color: '#fff' }}
            >
                <ArrowForwardIcon />
            </IconButton>
        </Box>
    );
};

export default IntroScreen;