import React, { useState } from 'react';
import { Box, Typography, Chip, IconButton } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface BadgeSelectionScreenProps {
    onNext: () => void; // Callback function to move to the next screen
    onBack: () => void; // Callback function to skip the badge selection
    setBadges: (value: string[]) => void; // Function to set the selected badges in the parent state
    selectedBadges: string[]; // Array of initially selected badges
    skipButton?: React.ReactNode; // Skip button callback
}

const BadgeSelectionScreen: React.FC<BadgeSelectionScreenProps> = ({ onNext, onBack, setBadges, selectedBadges, skipButton }) => {
    const badgeOptions = [
        { "label": "Book Lover", "icon": "📚" },
        { "label": "Science Enthusiast", "icon": "🔬" },
        { "label": "Nature Lover", "icon": "🌳" },
        { "label": "Philosophy & Thought", "icon": "🧠" },
        { "label": "Art Enthusiast", "icon": "🎭" },
        { "label": "Dreamer", "icon": "🌌" },
        { "label": "World Traveler", "icon": "🌍" },
        { "label": "Inventor & Creator", "icon": "💡" },
        { "label": "Film & Series Enthusiast", "icon": "🎥" },
        { "label": "Space Explorer", "icon": "🚀" },
        { "label": "Spiritual Seeker", "icon": "🧘‍♂️" },
        { "label": "Poetry & Words", "icon": "✍️" },
        { "label": "Tech & Gadgets", "icon": "💻" },
        { "label": "Design & Aesthetics", "icon": "🎨" }
    ];

    const [selectedOptions, setSelectedOptions] = useState<string[]>([...selectedBadges]);

    const handleSelect = (badge: string) => {
        if (selectedOptions.includes(badge)) {
            setSelectedOptions(selectedOptions.filter((item) => item !== badge));
        } else if (selectedOptions.length < 5) {
            setSelectedOptions([...selectedOptions, badge]);
        }
    };

    const handleNext = () => {
        setBadges([...selectedOptions]);
        onNext();
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '16px',
                width: '100%',
                maxWidth: '393px',
                mx: 'auto',
                boxSizing: 'border-box',
                overflow: 'hidden',
            }}
        >
            {/* Header */}
            <Typography variant="h4" sx={{ fontWeight: 700, fontSize: '28px', textAlign: 'center', mb: 2 }}>
                Choose  things you're really into
            </Typography>
            <Typography variant="body1" sx={{ color: '#6D6D6D', textAlign: 'center', mb: 2 }}>
                 help us find your best events and people.
            </Typography>

            {/* Badge Options */}
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '12px',
                    justifyContent: 'center',
                    mb: 3,
                }}
            >
                {badgeOptions.map((badge) => (
                    <Chip
                        key={badge.label}
                        label={`${badge.icon} ${badge.label}`}
                        onClick={() => handleSelect(badge.label)}
                        sx={{
                            cursor: 'pointer',
                            padding: '12px',
                            fontWeight: selectedOptions.includes(badge.label) ? 'bold' : 'normal',
                            backgroundColor: selectedOptions.includes(badge.label) ? '#007AFF' : '#F2F2F2',
                            color: selectedOptions.includes(badge.label) ? '#FFFFFF' : '#000000',
                            borderRadius: '25px',
                            fontSize: '16px',
                            '&:hover': {
                                backgroundColor: selectedOptions.includes(badge.label) ? '#005BBB' : '#E0E0E0',
                                color: selectedOptions.includes(badge.label) ? '#FFFFFF' : '#000000',
                            },
                        }}
                    />
                ))}
            </Box>

            {/* Navigation - Conditionally Rendered */}
            {
                <IconButton
                    onClick={handleNext}
                    sx={{ alignSelf: 'flex-end', marginTop: '24px', backgroundColor: '#007AFF', color: '#fff' }}
                >
                    <ArrowForwardIcon />
                </IconButton>
            }
             {/* Skip Button */}
             {skipButton && (
                <Box sx={{ alignSelf: 'center', marginTop: '16px' }}>
                    {skipButton} {/* Render skipButton as a ReactNode */}
                </Box>
            )}
        </Box>
    );
};

export default BadgeSelectionScreen;