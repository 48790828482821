import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Events from './pages/Events';
import TabLayout from './shared/components/TabLayout';
import EventDetail from './Features/Event/EventDetail';
import About from './pages/About';
import AboutSalonimLive from './pages/AboutSalonimLive'; // Import the new component
import PhoneVerificationModal from './shared/components/PhoneVerificationModal';
import Wizard from './Features/Onboarding/wizard/Wizard';
import FallbackProfilePage from './shared/components/FallbackProfilePage'; 
import { useAuth } from './context/AuthContext';
import StickyHeader from './Features/Profile/components/StickyHeader';
import { hotjar } from 'react-hotjar';
import PrivacyPolicy from './pages/PrivacyPolicy';
import MyEvent from './Features/Event/MyEvent';
import ProfilePage from './Features/Profile/Profile';
import PaymentPage from './pages/Payment';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const location = useLocation();
  const isTabHidden = location.pathname === '/onboarding';

  useEffect(() => {
    hotjar.initialize({ id: 5222814, sv: 6 });
  }, []);

  useEffect(() => {
    hotjar.stateChange(location.pathname);
  }, [location.pathname]);

  return (
    <div>
      <StickyHeader />
      {children}
      {!isTabHidden && <TabLayout children={undefined} />}
    </div>
  );
};

const AppRouter: React.FC = () => {
  const { user } = useAuth();

  return (
    <Router>
      <Layout>
        <Routes>
          {user ? (
            <>
              <Route path="/profile" element={<ProfilePage user={user} />} />
              <Route path="/events/management/:event_id" element={<MyEvent />} />
              <Route path="/payment/:event_id" element={<PaymentPage />} />
              <Route path="/" element={<Navigate to="/events" />} />
            </>
          ) : (
            <>
              <Route path="/profile" element={<FallbackProfilePage />} />
              <Route path="/" element={<Navigate to="/events" />} />
            </>
          )}
          <Route path="/onboarding" element={<Wizard />} />
          <Route path="/about" element={<About />} />
          <Route path="/about-salonim-live" element={<AboutSalonimLive />} /> {/* New route */}
          <Route path="/verification" element={<PhoneVerificationModal />} />
          <Route path="/events" element={<Events />} />
          <Route path="/events/:event_id" element={<EventDetail />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/payment" element={<FallbackProfilePage />} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default AppRouter;
