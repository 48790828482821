import React from 'react';
import { Link, Typography, Button } from '@mui/material'; // Ensure you're using Material-UI (MUI)



const App = () => {
    return (
        <div style={styles.app}>
            <header style={styles.header}>
                <h1 style={styles.headerTitle}>Salonim Live</h1>
                <p style={styles.headerSubtitle}>
                    Meet interesting people
                </p>
            </header>

            <div style={styles.container}>
                <section>
                    <h2>What is Salonim Live?</h2>
                    <p>
                        A unique platform that bridges the gap between the physical and digital worlds. After making connections in the app, we bring people together in a physical location. Using our algorithm, we find the most suitable people for our physical events.
                    </p>
                </section>

                <section>
                    <h2>How Does It Work?</h2>
                    <div style={styles.step}>
                        <h3>1. Sign Up</h3>
                        <p>Register in the app and fill in all your details.</p>
                    </div>
                    <div style={styles.step}>
                        <h3>2. Choose an Event</h3>
                        <p>Select an event you'd like to attend.</p>
                    </div>
                    <div style={styles.step}>
                        <h3>3. Algorithm Check</h3>
                        <p>Our algorithm checks if you're a match for the event based on age and interests of the attendees.</p>
                    </div>
                    <div style={styles.step}>
                        <h3>4. Approval and Access</h3>
                        <p>Once approved, you can pay for the event and receive updates about it.</p>
                    </div>
                    <div style={styles.step}>
                        <h3>5. Event Day</h3>
                        <p>At the event, you can see all details about the people attending with you.</p>
                    </div>
                    <div style={styles.step}>
                        <h3>6. AI Assistance</h3>
                        <p>Our AI system sends you notifications during the event about topics and people worth meeting.</p>
                    </div>
                </section>

                <section>
                    <h2>Where Are the Events?</h2>
                    <p>Events are held in intimate locations, currently in Tel Aviv, Israel.</p>
                </section>

                <section>
                    <h2>What Happens at the Event?</h2>
                    <p>The evening is divided into:
                        <ul>
                            <li>Mingling</li>
                            <li>3 Livers with Short 10-minute talks</li>
                            <li>Mingling with Live event features </li>
                        </ul>
                    </p>
                </section>

                <section>
                    <h2>Check Out Our Instagram Videos</h2>
                    <p>
                        Follow us on Instagram for the latest updates and event highlights:{' '}
                        <a
                            href="https://www.instagram.com/salonim.live"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={styles.link}
                        >
                            @salonim.live
                        </a>
                    </p>
                    <div style={styles.instagramVideos}>
                        <iframe
                            src="https://www.instagram.com/salonim.live/embed"
                            width="400"
                            height="480"
                            frameBorder="0"
                            scrolling="no"
                            title="Instagram Videos"
                            allowTransparency={true}
                        ></iframe>
                    </div>
                </section>

                  <section>
                    <h2>Access Our Web App</h2>
                    <p>
                        Use our web application at{' '}
                        <a
                            href="https://salonim.live"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={styles.link}
                        >
                            salonim.live
                        </a>
                    </p>
                    <p>Coming soon to Android and iPhone!</p>
                </section>
  {/* Privacy Policy */}
  <section>
                <Link
                    href="/privacy-policy"
                    target="_blank"
                    underline="none"
                    style={styles.privacyPolicyLink}
                >
                   Policy
                </Link>
            </section>
            </div>

            <footer style={styles.footer}>
                <p>
                    &copy; 2024 Salonim Live | All rights reserved |{' '}
                   
                </p>
            </footer>
        </div>
    );
};

const styles = {
    app: {
        fontFamily: 'Arial, sans-serif',
        lineHeight: 1.6,
        margin: 0,
        padding: 0,
        backgroundColor: '#f9f9f9',
        color: '#333',
    },
    header: {
        backgroundColor: '#4CAF50',
        color: 'white',
        padding: '20px',
        textAlign: 'center' as const,
    },
    headerTitle: {
        margin: 0,
        fontSize: '2.5rem',
    },
    headerSubtitle: {
        margin: '10px 0 0',
        fontSize: '1.2rem',
    },
    container: {
        padding: '20px',
    },
    feature: {
        backgroundColor: 'white',
        border: '1px solid #ddd',
        borderRadius: '8px',
        padding: '20px',
        textAlign: 'center' as const,
        margin: '10px 0',
    },
    privacyPolicyLink: {
        color: '#4CAF50',
        textDecoration: 'none',
        display: 'block',
        marginTop: '20px',
        textAlign: 'center' as const, // Ensure TypeScript understands this is a valid TextAlign value
    },
    step: {
        backgroundColor: 'white',
        border: '1px solid #ddd',
        borderRadius: '8px',
        padding: '20px',
        textAlign: 'center' as const,
        margin: '10px 0',
    },
    instagramVideos: {
        marginTop: '20px',
        textAlign: 'center' as const,
    },
    link: {
        color: '#4CAF50',
        textDecoration: 'none',
    },
    cta: {
        textAlign: 'center' as const,
        margin: '20px 0',
    },
    ctaButton: {
        backgroundColor: '#4CAF50',
        color: 'white',
        padding: '10px 20px',
        textDecoration: 'none',
        borderRadius: '5px',
        fontSize: '1.2rem',
    },
    footer: {
        backgroundColor: '#333',
        color: 'white',
        textAlign: 'center' as const,
        padding: '20px',
        marginTop: '20px',
    },
};

export default App;
