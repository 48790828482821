import React, { useEffect } from 'react';
import EventList from '../Features/Event/EventList';
import EventsToggle from '../shared/components/toggleBar';
import { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { getEvents } from '../shared/services/eventServices';
import sendwhatsapmsg from '../shared/services/General';

const Events: React.FC = () => {
    const [selectedTab, setSelectedTab] = useState('upcoming');
    const { user, loading } = useAuth();
    const navigate = useNavigate();

    const handleTabChange = (tab: any) => {
        console.log('Tab changed to:', tab);
        setSelectedTab(tab);

        if (tab === 'my') {

        }
    };

    useEffect(() => {
        // This code will run whenever the user state changes
        if (user) {
            // User is logged in
            console.log('User is logged in:', user);
        } else {
            // User is not logged in
            console.log('User is not logged in');
            // navigate('/verification'); // Redirect to login page
        }
    }, [user, navigate]);


    const [events, setEvents] = useState([]);

    const fetchEvents = async () => {
        // setLoading(true);

        try {
            const fetchedEvents: any = await getEvents(user?.user?.uid || '', selectedTab);
            // Update the event list state with the fetched events
            setEvents(fetchedEvents);
        } catch (error) {
            console.error('Error fetching events:', error);
        }

        // setLoading(false);
    };
    const style = {
        backgroundColor: 'white',
        height: '100%'
    };

    useEffect(() => {
        fetchEvents();
    }, []);

 

    return (
        <div style={{ marginTop: '10px', width: '100%', display: 'flex', flexDirection: 'column' }}>
            {/* <EventsToggle onTabChange={handleTabChange} /> */}
            <EventList type='events' events={events} tab={selectedTab} />
           
        </div>
    );
};

export default Events;
