import React, { useEffect, useState } from "react";
import { useAuth } from '../context/AuthContext';
import { useLocation, useNavigate, useParams } from "react-router-dom";

const PaymentPage = () => {
  const location = useLocation();
  const { user } = useAuth();
  const [iframeUrl, setIframeUrl] = useState("");
  const { event_id_route } = useParams(); // Get event ID and isRegister from the route
  const { event_id } = location.state?.data || event_id_route || '';
  const eventName = location.state?.data.name || ''; // Extract event name or use a fallback

  console.log('Hello');
  
  console.log(location.state);
  console.log(location.state.data.eventPrice);
  
  const navigate = useNavigate();
  // Only proceed if 'user' is available
  useEffect(() => {
    // Now that we have user, we can safely proceed
    const uid = user.uid;

    const makeApiCall = async () => {
      const apiUrl = "https://secure.cardcom.solutions/api/v11/LowProfile/Create";
      const payload = {
        TerminalNumber: 161524,
        ApiName: "MtaQMsAZEkso7vlxlLQs",
        ReturnValue: JSON.stringify({"uid" : uid, "eventId" : event_id,"priceEvent" : location.state.data.eventPrice }),
        UserName: "161524",
        Amount: location.state.data.eventPrice,
        SuccessRedirectUrl: `http://salonim.live/events/${event_id}`,
        FailedRedirectUrl: `http://salonim.live/failPayment`,
        WebHookUrl: `https://us-central1-salonim-29dcf.cloudfunctions.net/cardcomWebhook/`,
        Document: {
          To: "Salonim",
          Email: "info@salonim.live",
          Products: [
            {
              Description: "אירוע סלונים",
              UnitCost: location.state.data.eventPrice,
            },
          ],
        },
      };

      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          throw new Error("Failed to fetch payment URL");
        }

        const data = await response.json();

        // Check if the URL is available in the API response
        if (data.Url) {
          setIframeUrl(data.Url);
        } else {
          console.error("No URL returned in the response");
        }
      } catch (error) {
        console.error("Error occurred during the API call:", error);
      }
    };

    // Trigger the API call now that user is defined
    makeApiCall();
  }, [user]);

  // If user is still loading, display a loading message
  if (!user) {
    return <p>Loading payment...</p>;
  }

  return (
    <div style={{ height: "900px", overflow: "hidden" }}>
    {iframeUrl ? (
      <iframe
        src={iframeUrl}
        style={{
          width: "100%",
          height: "1000px", // Make iframe fill the div height
          border: "none", // No border for iframe
        }}
        title="Payment"
      />
    ) : (
      <p>Loading payment form...</p>
    )}
  </div>
  
  
  );
};

export default PaymentPage;