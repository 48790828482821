import React, { useState } from 'react';
import { Box, Typography, Checkbox, IconButton } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface PurposeScreenProps {
    onNext: () => void;
    onBack: () => void;
    setPurpose: (value: string[]) => void;
    selectedPurpose: string[];
    skipButton?: React.ReactNode; // Skip button callback
}

const PurposeScreen: React.FC<PurposeScreenProps> = ({ onNext, onBack, setPurpose, selectedPurpose, skipButton }) => {
    const purposeOptions = [
        { label: "Connect with interesting people", icon: "💡" },
        { label: "Share your knowledge with others", icon: "📚" },
        { label: "Meet a new romantic partner", icon: "👫" },
        { label: "Meet partners for next projects", icon: "🤝" },
        { label: "Learn and enrich yourself", icon: "🎓" },
    ];

    const [selectedOptions, setSelectedOptions] = useState<string[]>(selectedPurpose);

    const handleSelect = (option: string) => {
        setSelectedOptions((prev) =>
            prev.includes(option) ? prev.filter((item) => item !== option) : [...prev, option]
        );
    };

    const handleNext = () => {
        setPurpose(selectedOptions); // Save selected options to the wizard formData
        onNext(); // Move to the next screen
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                padding: '16px',
                width: '100%',
                maxWidth: '393px',
                mx: 'auto',
                boxSizing: 'border-box',
                overflow: 'hidden',
            }}
        >
            {/* Header */}
            <Box sx={{ padding: '0 0 20px' }}>
                <Typography variant="h4" sx={{ fontWeight: 700, fontSize: '32px', lineHeight: '38px', marginBottom: '12px' }}>
                    And what are you hoping to find?
                </Typography>
                <Typography sx={{ fontWeight: 500, fontSize: '16px', lineHeight: '24px', color: '#313131' }}>
                    It's your journey, choose the options that feel right for you.
                </Typography>
            </Box>

            {/* Options */}
            <Box sx={{ width: '100%' }}>
                {purposeOptions.map((option) => (
                    <Box
                        key={option.label}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            p: 2,
                            mb: 2,
                            borderRadius: 2,
                            bgcolor: selectedOptions.includes(option.label) ? '#007AFF' : '#F2F2F2',
                            cursor: 'pointer',
                        }}
                        onClick={() => handleSelect(option.label)} // Handle row click
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                            <Typography
                                sx={{
                                    fontSize: '1.5rem',
                                    mr: 2,
                                    color: selectedOptions.includes(option.label) ? '#fff' : '#000',
                                }}
                            >
                                {option.icon}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '1rem',
                                    fontWeight: selectedOptions.includes(option.label) ? 'bold' : 'normal',
                                    color: selectedOptions.includes(option.label) ? '#fff' : '#000',
                                }}
                            >
                                {option.label}
                            </Typography>
                        </Box>

                        <Checkbox
                            checked={selectedOptions.includes(option.label)}
                            onChange={() => handleSelect(option.label)} // Handle checkbox click
                            onClick={(e) => e.stopPropagation()} // Prevent row click when clicking checkbox
                            sx={{
                                color: selectedOptions.includes(option.label) ? '#fff' : 'rgba(0, 0, 0, 0.54)',
                                '&.Mui-checked': {
                                    color: '#fff',
                                },
                            }}
                        />
                    </Box>
                ))}
            </Box>

            {/* Conditionally Rendered Next Button */}
            {(
                <IconButton
                    onClick={handleNext}
                    sx={{ alignSelf: 'flex-end', backgroundColor: '#007AFF', color: '#fff' }}
                >
                    <ArrowForwardIcon />
                </IconButton>
            )}

             {/* Skip Button */}
             {skipButton && (
                <Box sx={{ alignSelf: 'center', marginTop: '16px' }}>
                    {skipButton} {/* Render skipButton as a ReactNode */}
                </Box>
            )}
        </Box>
    );
};

export default PurposeScreen;