import React, { useRef, useState } from 'react';
import { Box, Typography, CircularProgress, Button } from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

interface ImageUploadScreenProps {
    onBack: () => void;
    onNext: () => void;
    onImageUpload: (image: string | null) => void;
    uploadedImage: string | null;
    skipButton?: React.ReactNode;
}

const ImageUploadScreen: React.FC<ImageUploadScreenProps> = ({
    onNext,
    onBack,
    onImageUpload,
    uploadedImage,
    skipButton
}) => {
    const [loading, setLoading] = useState(false);

    // 1) Create a ref for the hidden file input
    const fileInputRef = useRef<HTMLInputElement>(null);

    // 2) This function programmatically opens the file browser
    const chooseImage = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    // 3) Handle the file once user selects it
    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            setLoading(true);

            try {
                const storage = getStorage();
                const storageRef = ref(storage, `images/${Date.now()}-${file.name}`);

                // Upload the file
                const snapshot = await uploadBytes(storageRef, file);
                const downloadURL = await getDownloadURL(snapshot.ref);

                // Notify parent about the successful upload
                onImageUpload(downloadURL);

            } catch (error) {
                console.error('Error uploading image:', error);
                // Let the user know something went wrong, and prompt them to upload a file
                alert('Failed to upload. Please upload a file from your library instead of taking a photo.');
            } finally {
                setLoading(false);
            }
        }
    };


    const handleSubmit = () => {
        if (uploadedImage) {
            onNext();
        } else {
            alert('Please upload an image before proceeding.');
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '16px',
                width: '100%',
                maxWidth: '393px',
                mx: 'auto',
                boxSizing: 'border-box',
                overflow: 'hidden',
            }}
        >
            <Typography variant="h4" sx={{ fontWeight: 700, fontSize: '28px', textAlign: 'center', mb: 2 }}>
                Time to put a face to your name
            </Typography>

            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '240px',
                    height: '240px',
                    border: '2px dashed #007AFF',
                    borderRadius: '12px',
                    mb: 3,
                    backgroundColor: '#F5F5F5',
                    position: 'relative',
                    overflow: 'hidden',
                }}
            >
                {loading ? (
                    <CircularProgress />
                ) : uploadedImage ? (
                    <img
                        src={uploadedImage}
                        alt="Uploaded"
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                        }}
                    />
                ) : (
                    <>
                        <AddAPhotoIcon sx={{ fontSize: '48px', color: '#007AFF' }} />
                        <input
                            ref={fileInputRef}
                            type="file"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />

                        <button onClick={chooseImage}>Choose Image</button>

                    </>
                )}
            </Box>

            <Button
                variant="contained"
                onClick={handleSubmit}
                sx={{
                    marginTop: '24px',
                    backgroundColor: '#007AFF',
                    color: '#fff',
                    alignSelf: 'center',
                }}
                endIcon={<ArrowForwardIcon />}
            >
                Next
            </Button>

            {skipButton && <Box sx={{ marginTop: '16px' }}>{skipButton}</Box>}
        </Box>
    );
};

export default ImageUploadScreen;